import { useTranslation } from "react-i18next";
import "./ComingSoon.scss";
const ComingSoon = () => {
  const { t } = useTranslation();
  return (
    <div id="comingSoonScreen">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="comingSoonMsg">
              <img
                src={require("../../utils/Images/comingSoon.png")}
                alt={t("GENERAL.SOMETHING_WENT_WRONG")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
