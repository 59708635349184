import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { AssessmentButton, InputField } from "ui-components";
import { loginUserData } from "../../../utils/Common/Constant";
import * as Yup from "yup";
import ApplicantSignInWrapper from "../../../components/ApplicantSignInWrapper/ApplicantSignInWrapper";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18nConfig";
const CreateNewPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  // login User Schema
  const loginUserSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t("ERROR.PASSWORD_MUST"))
      .max(32, t("ERROR.PASSWORD_MUST"))
      .matches(/[a-zA-Z]/, t("ERROR.PASSWORD_LETTER"))
      .matches(/[0-9]/, t("ERROR.PASSWORD_NUMBER"))
      .matches(
        /[!@#$%^&*(),.?":{}\\|<>`~\-_=+[\];:'"<>/?]/,
        t("ERROR.PASSWORD_SPECIAL"),
      )

      .trim()
      .required(t("ERROR.PASSWORD_REQUIRED")),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        t("ERROR.CONFIRM_PASSWORD_MATCH"),
      )
      .trim()
      .required(t("ERROR.CONFIRM_PASSWORD_REQUIRED")),
  });
  const handleConfirmPassword = (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    setSubmitting && setSubmitting(true);
    resetForm && resetForm();
    navigate(`/${currentLanguage}/applicant-login`, { replace: true });
    console.log(value, resetForm, setSubmitting);
  };
  return (
    <ApplicantSignInWrapper>
      <Formik
        initialValues={loginUserData}
        validationSchema={loginUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          handleConfirmPassword(value, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <div className="userContent">
                <h1 className="userHeadingApp">
                  {t("GENERAL.CREATE_NEW_PASS")}
                </h1>
                <h1 className="userHeading">{t("GENERAL.CANDIDATE_LOGIN")}</h1>
                <div className="mt-5">
                  <Field name="password">
                    {() => (
                      <div className="passwordSec">
                        <InputField
                          placeholder={`${t("GENERAL.PASSWORD")} *`}
                          onChange={(newValue) =>
                            setFieldValue("password", newValue)
                          }
                          value={values.password}
                          additionalProps={{
                            className: "passwordApplicantLogin",
                          }}
                          type="password"
                        />
                      </div>
                    )}
                  </Field>
                  {errors?.password && touched?.password ? (
                    <div className="errorMessage">{errors?.password}</div>
                  ) : null}
                  <Field name="confirmPassword">
                    {() => (
                      <div className="passwordSec">
                        <InputField
                          placeholder={`${t("GENERAL.CONFIRM_PASSWORD")} *`}
                          onChange={(newValue) =>
                            setFieldValue("confirmPassword", newValue)
                          }
                          value={values.confirmPassword}
                          additionalProps={{
                            className: "passwordApplicantLogin",
                          }}
                          type="password"
                        />
                      </div>
                    )}
                  </Field>
                  {errors?.confirmPassword && touched?.confirmPassword ? (
                    <div className="errorMessage">
                      {errors?.confirmPassword}
                    </div>
                  ) : null}
                </div>
                <AssessmentButton
                  className="submitBtn"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? t("GENERAL.LOADING") : t("GENERAL.SAVE")}
                </AssessmentButton>
              </div>
              <div className="password-instructions">
                <div>
                  <li>{t("GENERAL.INSTRUCTION1")}</li>
                  <li>{t("GENERAL.INSTRUCTION2")}</li>
                  <li>{t("GENERAL.INSTRUCTION3")}</li>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ApplicantSignInWrapper>
  );
};
export default CreateNewPassword;
