import "./ApplicantSignInWrapper.scss";
const ApplicantSignInWrapper = ({ children }: any) => {
  return (
    <div className="loginPage">
      <div className="container-fluid ">
        <div className="row login-container">
          <div className="col-md-6">
            <div className="loginBg">
              <img src={require("../../utils/Images/ApplicantBg.png")} alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="right-part">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplicantSignInWrapper;
