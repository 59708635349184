import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { AssessmentButton, InputField } from "ui-components";
import { loginUserData } from "../../../utils/Common/Constant";
// import * as Yup from "yup";
import ApplicantSignInWrapper from "../../../components/ApplicantSignInWrapper/ApplicantSignInWrapper";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18nConfig";
const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  // login User Schema
  // const loginUserSchema = Yup.object().shape({
  //     email: Yup.string()
  //         .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
  //         .required(t("ERROR.EMAIL_REQUIRED")),
  // });
  return (
    <ApplicantSignInWrapper>
      <Formik
        onSubmit={() => {}}
        initialValues={loginUserData}
        // validationSchema={loginUserSchema}
        // onSubmit={(value, { resetForm, setSubmitting }) => {
        // handleApplicantLogin(value, resetForm, setSubmitting);
        // handleApplicantLogin()
        // }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <div className="userContent">
                <h1 className="userHeadingApp">
                  {t("GENERAL.ENTER_YOUR_EMAIL")}
                </h1>
                <div className="mt-5">
                  <Field name="email">
                    {() => (
                      <InputField
                        placeholder={`${t("GENERAL.EMAIL_ADDRESS")} *`}
                        onChange={(newValue: any) =>
                          setFieldValue("email", newValue.trim())
                        }
                        value={values.email}
                        additionalProps={{ className: "emailApplicantLogin" }}
                      />
                    )}
                  </Field>
                  {errors?.email && touched?.email ? (
                    <div className="errorMessage">{errors?.email}</div>
                  ) : null}
                </div>
                <AssessmentButton
                  className="submitBtn"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() =>
                    navigate(`/${currentLanguage}/create-password`, {
                      replace: true,
                    })
                  }
                >
                  {isSubmitting ? t("GENERAL.LOADING") : t("GENERAL.GET_LINK")}
                </AssessmentButton>
                <div className="alreadyHaveAccout">
                  <span>
                    {t("GENERAL.ALREADY_HAVE_ACC")}{" "}
                    <span
                      className="signIn-text"
                      onClick={() =>
                        navigate(`/${currentLanguage}/applicant-login`, {
                          replace: true,
                        })
                      }
                    >
                      {t("GENERAL.SIGN_IN")}
                    </span>
                  </span>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ApplicantSignInWrapper>
  );
};
export default ForgotPassword;
