import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import SubmitAssessment from "./pages/SubmitAssessment";
import AssessmentDetails from "./pages/SubmitAssessment/AssessmentDetails/AssessmentDetails";
import QuestionList from "./pages/SubmitAssessment/QuestionSet/QuestionList";
import { extractLanguage } from "./utils/Common/Constant";
import { useEffect } from "react";
import i18n from "./i18nConfig";
import ApplicantLogin from "./pages/SubmitAssessment/Auth/ApplicantLogin";
import {
  PrivateApplicantRoute,
  PublicApplicantRoute,
} from "./utils/AuthRoutes/AuthRoutes";
import InvitedUserAuth from "./pages/SubmitAssessment/Auth/InvitedUserAuth";
import NotFound from "./pages/NotFound/NotFound";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import Feedback from "./pages/SubmitAssessment/Feedback/Feedback";
import Requirements from "./pages/SubmitAssessment/Requirements/Requirements";
import ForgotPassword from "./pages/SubmitAssessment/ForgotPassword/ForgotPassword";
import CreateNewPassword from "./pages/SubmitAssessment/CreateNewPassword/CreateNewPassword";
function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    const language: any = extractLanguage(pathname);
    i18n.changeLanguage(language);
  }, [pathname]);
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={<Navigate to={"/en/applicant-login"} replace />}
        />
        <Route
          path="/:languageParam/submitassessment"
          element={
            <PrivateApplicantRoute>
              <SubmitAssessment />
            </PrivateApplicantRoute>
          }
        />
        <Route
          path="/:languageParam/assessmentdetails/:assessmentIdParam"
          element={<AssessmentDetails />}
        />
        <Route
          path="/:languageParam/startassessment"
          element={<QuestionList />}
        />
        <Route path="/:languageParam/feedback" element={<Feedback />} />
        <Route
          path="/:languageParam/applicant-login"
          element={
            <PublicApplicantRoute>
              <ApplicantLogin />
            </PublicApplicantRoute>
          }
        />
        <Route
          path="/:languageParam/forgot-password"
          element={
            <PublicApplicantRoute>
              <ForgotPassword />
            </PublicApplicantRoute>
          }
        />
        <Route
          path="/:languageParam/create-password"
          element={
            <PublicApplicantRoute>
              <CreateNewPassword />
            </PublicApplicantRoute>
          }
        />
        <Route
          path="/:languageParam/requirements"
          element={
            <PrivateApplicantRoute>
              <Requirements />
            </PrivateApplicantRoute>
          }
        />
        <Route
          path="/:languageParam/404-not-found-error"
          element={<NotFound />}
        />
        <Route
          path="/:languageParam/unauthorized-error"
          element={<Unauthorized />}
        />
        <Route path="*" element={<InvitedUserAuth />} />
      </Routes>
    </div>
  );
}

export default App;
