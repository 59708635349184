import ComingSoon from "../../../components/ComingSoon/ComingSoon";
import SubmitAssessmentHeader from "../../../components/SubmitAssessment/Header/SubmitAssessmentHeader";

const Requirements = () => {
  return (
    <div>
      <SubmitAssessmentHeader />
      <ComingSoon />
    </div>
  );
};
export default Requirements;
