import EssayType from "./QuestionType/EssayType";
import MultipleType from "./QuestionType/MultipleType";
import SingleType from "./QuestionType/SingleType";
import StartCamera from "./StartCamera";
import VideoType from "./QuestionType/VideoType";
import CodeEditor from "./ProgrammingQuestion/CodeEditor";
import FullPageLoader from "../../../components/Loader/FullPageLoader";
// import BlinkAnimation from "../../../utils/Images/BlinkAnimation.gif";
import { Bullets } from "ui-components";
import Timer from "../../../utils/Images/Timer";
import { formatTime } from "../../../utils/Common/Constant";
interface AnswerQuestion {
  setSingleTypeValue: any;
  singleTypeValue: any;
  setMultipleTypeValue: any;
  multipleTypeValue: any;
  setEssayTypeValue: any;
  essayTypeValue: any;
  questionData: any;
  subTimer: number;
  SpeechRecognition: any;
  browserSupportsSpeechRecognition: any;
  transcript: string;
  currentQuestionIndex: number;
  transcriptEnabled: boolean;
  codingAnsTemplate: string;
  setCodingAnsTemplate: any;
  codeOutPut: any;
  testCases: any;
  setTestCases: any;
  setCodeOutput: any;
  handleSubmitAnswer: any;
  setStartQuestionLoader: any;
  startQuestionLoader: boolean;
  setDisableNext: any;
  isBlinking: boolean;
}

const AnswerQuestion: React.FC<AnswerQuestion> = ({
  setSingleTypeValue,
  singleTypeValue,
  setMultipleTypeValue,
  multipleTypeValue,
  setEssayTypeValue,
  essayTypeValue,
  questionData,
  subTimer,
  SpeechRecognition,
  browserSupportsSpeechRecognition,
  transcript,
  currentQuestionIndex,
  transcriptEnabled,
  codingAnsTemplate,
  setCodingAnsTemplate,
  codeOutPut,
  testCases,
  setTestCases,
  setCodeOutput,
  handleSubmitAnswer,
  setDisableNext,
  setStartQuestionLoader,
  startQuestionLoader,
  isBlinking,
}) => {
  const renderQuestionType = (
    questionData: any,
    transcript: string,
    browserSupportsSpeechRecognition: any,
  ) => {
    switch (questionData?.types ?? questionData?.type) {
      case "Essay":
        return (
          <EssayType
            startQuestionLoader={startQuestionLoader}
            setEssayTypeValue={setEssayTypeValue}
            essayTypeValue={essayTypeValue}
          />
        );
      case "Single":
        return (
          <SingleType
            data={questionData}
            setSingleTypeValue={setSingleTypeValue}
            singleTypeValue={singleTypeValue}
            startQuestionLoader={startQuestionLoader}
          />
        );
      case "Multiple":
        return (
          <MultipleType
            data={questionData}
            setMultipleTypeValue={setMultipleTypeValue}
            multipleTypeValue={multipleTypeValue}
            startQuestionLoader={startQuestionLoader}
          />
        );
      case "Video":
        return (
          <VideoType
            browserSupportsSpeechRecognition={browserSupportsSpeechRecognition}
            transcript={transcript}
            transcriptEnabled={transcriptEnabled}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="row mt-4">
      {(questionData?.types ?? questionData?.type === "Coding") ? (
        <div className="col-lg-12 col-md-12 col-sm-12 posRelate">
          <div className="codingQuestion">
            <div className="codingQuestionDisplay">
              <p>Q.{currentQuestionIndex ?? 1}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: `${questionData?.question?.question || questionData?.question || ""}`,
                }}
              />
            </div>
            {questionData?.attachment && (
              <div className="ImageAttParent mb-2">
                <img
                  className="attachedImageQuestion"
                  src={questionData?.attachment}
                  alt="Attachment"
                />
              </div>
            )}
          </div>
          <CodeEditor
            questionData={questionData}
            codingAnsTemplate={codingAnsTemplate}
            setCodingAnsTemplate={setCodingAnsTemplate}
            setTestCases={setTestCases}
            setCodeOutput={setCodeOutput}
            codeOutPut={codeOutPut}
            testCases={testCases}
            timer={subTimer}
            currentQuestionIndex={currentQuestionIndex}
            questionId={questionData?.assessmentApplicantQuestionId}
            handleSubmitAnswer={handleSubmitAnswer}
            setDisableNext={setDisableNext}
            setStartQuestionLoader={setStartQuestionLoader}
            startQuestionLoader={startQuestionLoader}
          />
        </div>
      ) : (
        <>
          <div className="col-lg-7 col-md-6 col-sm-12">
            <div className="questionSet">
              <div className="questionDisplay">
                <div className="question">
                  <div className="questionlist">
                    <div className="d-flex mb-1">
                      {questionData?.skillName && (
                        <Bullets skillName={questionData?.skillName} />
                      )}
                    </div>
                    <div className="questionDisplay">
                      <p>
                        Q.{currentQuestionIndex > 0 ? currentQuestionIndex : ""}
                      </p>
                      <p
                        className="currentQuestionIndex"
                        dangerouslySetInnerHTML={{
                          __html: `${questionData?.question?.question || questionData?.question || "-"}`,
                        }}
                      />
                    </div>
                    {questionData?.attachment && (
                      <div className="ImageAttParent mb-2">
                        <img
                          className="attachedImageQuestion"
                          src={questionData?.attachment}
                          alt="Attachment"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {renderQuestionType(
                questionData,
                transcript,
                browserSupportsSpeechRecognition,
              )}
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 notforcodingquestion">
            <div className="recordingAction">
              <div
                className={`${isBlinking ? "globalTimeBlink" : "globalTime"}`}
              >
                <div>
                  <Timer
                    color={isBlinking ? "var(--red))" : "var(--primary-green)"}
                  />
                </div>
                <p className={` ${isBlinking ? "blink" : "timer"}`}>
                  {formatTime(subTimer) ?? 0} sec
                </p>
              </div>
              <div className="blinkAnime"></div>
            </div>
            {(startQuestionLoader ? true : false) ? (
              <FullPageLoader isText={true} />
            ) : (
              <StartCamera
                SpeechRecognition={SpeechRecognition}
                timer={subTimer}
                currentQuestionIndex={currentQuestionIndex}
                questionId={questionData?.assessmentApplicantQuestionId}
                data={questionData}
                handleSubmitAnswer={handleSubmitAnswer}
                setStartQuestionLoader={setStartQuestionLoader}
                startQuestionLoader={startQuestionLoader}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AnswerQuestion;
